(function () {
	const mq = matchMedia('(min-width: 1025px)');
	handle(mq);

	// ウィンドウサイズが変更されても実行されるように
	mq.addListener(handle);

	function handle(mq) {
		if (!mq.matches) {
			// 1024px以下 SP - TB
			// 絞り込み検索１階層選択、データセット
			const refinesearch_select__sub = document.getElementsByClassName('p-sidebar__item');
			for (let i = 0; i < refinesearch_select__sub.length; i++) {
				refinesearch_select__sub[i].addEventListener('click', (e) => {
					if (refinesearch_select__sub[i].lastElementChild.classList.contains('p-sidebar__sub__contents')) {
						refinesearch_select__sub[i].lastElementChild.classList.add('is-open');
					} else {
						let data = refinesearch_select__sub[i].getAttribute('value');
						let target = refinesearch_select__sub[i].closest('.p-sidebar__contents').nextElementSibling;

						if (refinesearch_select__sub[i].closest('.p-sidebar__section').classList.contains('p-sidebar--stock') || refinesearch_select__sub[i].closest('.p-sidebar__section').classList.contains('p-sidebar--color')) {
							const length = refinesearch_select__sub[i].parentNode.children.length;

							for (let c = 0; c < length; c++) {
								if (refinesearch_select__sub[i].parentNode.children[c].classList.contains('is-select')) {
									refinesearch_select__sub[i].parentNode.children[c].classList.remove('is-select');
								}
							}
							refinesearch_select__sub[i].classList.add('is-select');

						} else {
							target.innerHTML = data;
							target.setAttribute('data-value', data);
							refinesearch_select__sub[i].closest('.p-sidebar__contents').classList.remove('is-open');
						}
					}
				});
			}

			// 絞り込み検索 二階層目、データセット
			const refinesearch_sub_item = document.getElementsByClassName('p-sidebar__sublist__item');
			for (let i = 0; i < refinesearch_sub_item.length; i++) {
				refinesearch_sub_item[i].addEventListener('click', (e) => {
					e.stopPropagation();
					let data = refinesearch_sub_item[i].getAttribute('value');
					let target = refinesearch_sub_item[i].closest('.p-sidebar__contents').nextElementSibling;
					target.innerHTML = data;
					target.setAttribute('data-value', data);
					let aa = refinesearch_sub_item[i].closest('.p-sidebar__sub__contents');
					aa.classList.remove('is-open');
					refinesearch_sub_item[i].closest('.p-sidebar__contents').classList.remove('is-open');
				});
			}

			// 絞り込み検索　リセット
			const refinesearch_reset = document.getElementsByClassName('c-button--reset');
			for (let i = 0; i < refinesearch_reset.length; i++) {
				refinesearch_reset[i].addEventListener('click', (e) => {
					e.stopPropagation();
					for (let x = 0; x < document.getElementsByClassName('c-valuebox').length; x++) {
						document.getElementsByClassName('c-valuebox')[x].setAttribute('data-value', '指定なし');
						document.getElementsByClassName('c-valuebox')[x].innerHTML = '指定なし';
					}
					const selectitem = document.querySelectorAll('.p-sidebar__contents .is-select');
					for (let i = 0; s < selectitem.length; i++) {
						selectitem[i].classList.remove('is-select');
					}
				});
			}

			// 絞り込み検索 サブディレクトリ方メインカテゴリへ戻る
			const return_button = document.getElementsByClassName('js-refinesearch-back');
			for (let i = 0; i < return_button.length; i++) {
				return_button[i].addEventListener('click', (e) => {
					e.stopPropagation();
					return_button[i].parentNode.parentNode.classList.remove('is-open');
				});
			}

			// 絞り込み検索 サブディレクトリ表示
			const refinesearch_select = document.getElementsByClassName('c-valuebox');
			for (let i = 0; i < refinesearch_select.length; i++) {
				refinesearch_select[i].addEventListener('click', () => {
					refinesearch_select[i].previousElementSibling.classList.add(
						'is-open'
					);
				});
			}
		}
	}
})();

