'use strict';

import 'picturefill';
import 'picturefill/dist/plugins/intrinsic-dimension/pf.intrinsic.min.js';

import Stickyfill from 'stickyfilljs';
if (document.getElementsByClassName('l-header')[0]) {
	const sticky_elements = document.getElementsByClassName('l-header')[0];
	Stickyfill.add(sticky_elements);
}
const header = document.getElementsByClassName('l-header')[0];
const drwer = document.getElementsByClassName('p-drwer')[0];
const html = document.getElementsByTagName('html')[0];
const body = document.getElementsByTagName('body')[0];
const refinesearch = document.getElementsByClassName('l-container__sidebar')[0];
const searchbox = document.querySelectorAll('.js-searchbox');
const headDropTool = document.querySelectorAll('.js-header-drop--pc');

const fixwindow = (el) => {
	if (el.classList.contains('is-open') || header.classList.contains('is-open')) {
		el.classList.remove('is-open');
		body.setAttribute('style', '');
		html.setAttribute('style', '');
		let data = sessionStorage.getItem('ySctoll');
		scrollTo(0, data);
	} else {
		el.classList.add('is-open');
		let ySctoll = window.pageYOffset;
		sessionStorage.setItem('ySctoll', ySctoll);
		body.setAttribute('style', 'position:fixed; top:' + -ySctoll + 'px;');
		html.setAttribute('style', 'overflow:hidden');

		if (el == refinesearch) {
			const valueItem = document.querySelectorAll('.l-container__sidebar .c-valuebox');
			let valuelength = valueItem.length;

			for (var i = 0; i < valuelength; i++) {
				let value = valueItem[i].dataset.value;
				valueItem[i].innerHTML = value;
			}
		}

	}
}

// * HEADER *//
if (document.querySelector('.l-header') != null) {
	// HEADER drwer
	const drwerToggle = document.querySelectorAll('.js-drwer-toggle');
	Array.prototype.forEach.call(drwerToggle, function (drwerToggle) {
		drwerToggle.addEventListener('click', (e) => {
			fixwindow(header);
			if (searchbox[0].parentNode.classList.contains('is-open')) {
				searchbox[0].parentNode.classList.remove('is-open');
			}
		})
	});

	const drwerContents = document.getElementsByClassName('p-header-navigation')[0];
	// ドロワー以外の場所をクリックでモーダル閉じる
	document.addEventListener('click', (e) => {
		if (header.classList.contains('is-open')) {
			if (!e.target.closest('.p-header-navigation__inner') && !e.target.classList.contains('js-drwer-toggle')) {
				// クリックした部分の先祖にp-header-navigation__innerがない
				// クリックした部分の要素のクラスにjs-drwer-toggleがない
				body.setAttribute('style', '');
				html.setAttribute('style', '');
				let data = sessionStorage.getItem('ySctoll');
				scrollTo(0, data);
				header.classList.remove('is-open');
			}
		} else {
			return false;
		}
	})

	// HEADER drwer close
	const drwerClose = document.querySelectorAll('.js-drwerClose');
	Array.prototype.forEach.call(drwerClose, (drwerClose) => {
		drwerClose.addEventListener('click', (e) => {
			header.classList.remove('is-open');
			body.setAttribute('style', '');
			html.setAttribute('style', '');
			let data = sessionStorage.getItem('ySctoll');
			scrollTo(0, data);
		});
	});

	// HEADER search
	Array.prototype.forEach.call(searchbox, (searchbox) => {
		searchbox.addEventListener('click', () => {
			if (window.innerWidth < 1024) {
				if (header.classList.contains('is-open')){
					header.classList.remove('is-open');
				}
				fixwindow(searchbox);
			}
			else {
				for (let i = 0; i < headDropTool.length; i++) {
					headDropTool[i].classList.remove('is-open');
				}
				if(searchbox.parentNode.classList.contains('is-open')){
					searchbox.parentNode.classList.remove('is-open');
				}else{
					searchbox.parentNode.classList.add('is-open');

				}
			}

		});

	});


	// HEADER TOOL
	Array.prototype.forEach.call(headDropTool, (headDropTool) => {
		headDropTool.querySelector('.p-header-tool__link').addEventListener('click', (e) => {
			e.stopPropagation();
			if (window.innerWidth > 1024) {
				if (!headDropTool.classList.contains('is-open')) {
					for (let i = 0; i < document.querySelectorAll('.js-header-drop--pc').length; i++) {
						document.querySelectorAll('.js-header-drop--pc')[i].classList.remove('is-open');
					}

					if (searchbox[0].parentNode.classList.contains('is-open')) {
						searchbox[0].parentNode.classList.remove('is-open');
					}

					headDropTool.classList.add('is-open');
				}else{
					for (let i = 0; headDropTool.length > i; i++) {
						if (headDropTool[i].classList.contains('is-open')) {
							headDropTool[i].classList.remove('is-open');
						}
					}
					headDropTool[i].classList.remove('is-open');
				}
			}
		});
	});

}

//* COMMON *//
// accodion SP only
const searchClose = document.querySelectorAll('.js-searchclose');
Array.prototype.forEach.call(searchClose, (searchClose) => {
	searchClose.addEventListener('click', (e) => {
		if (window.innerWidth < 1024) {
			fixwindow(searchbox[0]);
		} else {
			searchbox[0].parentNode.classList.add('is-open');
		}
	});
});

// accodion SP only
const sideAccodion = document.querySelectorAll('.js-accodion--sidebar');
Array.prototype.forEach.call(sideAccodion, (sideAccodion) => {
	sideAccodion.addEventListener('click', (e) => {
		if (window.innerWidth < 1024) {
			fixwindow(searchbox[0]);
		} else {
			sideAccodion.accodion(accodionToggle);
		}
	});
});

const sideAccodionSub = document.querySelectorAll('.p-sidebar__item');
const sidePcAccodion = (el) => {
	if (el.getAttribute('data-accodion') == "true" && el.classList.contains('is-open')) {
		el.classList.remove('is-open');
		let height = el.querySelector('.p-sidebar__sublist').clientHeight;
		el.querySelector('.p-sidebar__sub__contents').setAttribute('style', 'height:' + 0 + 'px;');
		let wrapHeight = el.closest('.p-sidebar__contents').clientHeight - height;
		el.closest('.p-sidebar__contents').setAttribute('style', 'height:' + wrapHeight + 'px');

	} else if (el.getAttribute('data-accodion') == "true" && !el.classList.contains('is-open')) {
		el.classList.add('is-open');
		let height = el.querySelector('.p-sidebar__sublist').clientHeight;
		el.querySelector('.p-sidebar__sub__contents').setAttribute('style', 'height:' + height + 'px;');
		let wrapHeight = el.closest('.p-sidebar__contents').clientHeight + height;
		el.closest('.p-sidebar__contents').setAttribute('style', 'height:' + wrapHeight + 'px');
	}
}
Array.prototype.forEach.call(sideAccodionSub, (sideAccodionSub) => {
	sideAccodionSub.addEventListener('click', (e) => {
		if (1024 < window.innerWidth) {
			sidePcAccodion(sideAccodionSub);
		}
	});
});

//LeftNavi.jsに移行
// PRODUCT-LIST REFINESEARCH
//if (document.querySelector('.js-refinesearch')) {
//	const refinesearchToggle = document.querySelectorAll('.js-refinesearch');
//	Array.prototype.forEach.call(refinesearchToggle, (refinesearchToggle) => {
//		refinesearchToggle.addEventListener('click', (e) => {
//			e.stopPropagation();
//			fixwindow(refinesearch);
//		});
//	});
//};

// COMMON ACCODION
const accodionToggle = document.querySelectorAll('.js-accodion');
Array.prototype.forEach.call(accodionToggle, (accodionToggle) => {
	accodionToggle.addEventListener('click', (e) => {
		accodion(accodionToggle);
	});
});

// COMMON ACCODION PC-ONLY
const accodionTogglePc = document.querySelectorAll('.js-accodion--pc');
Array.prototype.forEach.call(accodionTogglePc, (accodionTogglePc) => {
	accodionTogglePc.addEventListener('click', (e) => {
		if (window.innerWidth > 1024) {
			accodion(accodionTogglePc);
		}
	});
});

// COMMON ACCODION SP-ONLY
const accodionToggleSp = document.querySelectorAll('.js-accodion--sp');
Array.prototype.forEach.call(accodionToggleSp, (accodionToggleSp) => {
	accodionToggleSp.addEventListener('click', (e) => {
		if (window.innerWidth < 1024) {
			accodion(accodionToggleSp);
		}
	});
});

// COMMON SP:ACCODION / PC:DROPDOWN
const childShow = document.querySelectorAll('.js-childShow');
Array.prototype.forEach.call(childShow, (childShow) => {
	childShow.firstElementChild.addEventListener('click', (e) => {
		e.preventDefault();
		if (window.innerWidth < 1024) {
			if (childShow.classList.contains('is-show')) {
				childShow.classList.remove('is-show');
				childShow.querySelectorAll('.p-header-navigation__container')[0].setAttribute('style', 'height: 0;')
			} else {
				childShow.classList.add('is-show');
				let height = childShow.querySelectorAll('.p-header-subNavigation')[0].clientHeight;
				childShow.querySelectorAll('.p-header-navigation__container')[0].setAttribute('style', 'height:' + height + 'px;')
			}
		} else {
			return false;
		}
	});
	childShow.addEventListener('mouseover', () => {
		if (window.innerWidth > 1024) {
			childShow.classList.add('is-show');

			if (searchbox[0].parentNode.classList.contains('is-open')) {
				searchbox[0].parentNode.classList.remove('is-open');
			}

			for (let i = 0; i < headDropTool.length; i++) {
				headDropTool[i].classList.remove('is-open');
			}
		}
	})
	childShow.addEventListener('mouseleave', () => {
		if (window.innerWidth > 1024) {
			childShow.classList.remove('is-show');


		}
	})
});

// ページロード時にサイドナビにos-openがあったら開く。
window.addEventListener('load', () => {
	if (document.querySelector(".p-sidebar__heading.is-open")) {
		let target = document.querySelectorAll(".p-sidebar__heading.is-open");

		if (target.length && 1024 < window.innerWidth) {
			for (let i = 0; i < target.length; i++) {
				let height = target[i].nextElementSibling.firstElementChild.clientHeight;
				target[i].nextElementSibling.style.height = height + 'px';
			}
		}
	}
});

// FAVORITE
const favoriteSwitch = document.querySelectorAll('.js-favorite');
Array.prototype.forEach.call(favoriteSwitch, (favoriteSwitch) => {
	favoriteSwitch.addEventListener('click', (e) => {
		e.preventDefault();
		if (favoriteSwitch.classList.contains('is-select')) {
			favoriteSwitch.classList.remove('is-select');
			let path = 'M14.5 0A5.988 5.988 0 0010 2.09 5.988 5.988 0 005.5 0 5.447 5.447 0 000 5.5c0 3.78 3.4 6.86 8.55 11.54L10 18.35l1.45-1.32C16.6 12.36 20 9.28 20 5.5A5.447 5.447 0 0014.5 0zm-4.4 15.55l-.1.1-.1-.1C5.14 11.24 2 8.39 2 5.5A3.418 3.418 0 015.5 2a3.909 3.909 0 013.57 2.36h1.87A3.885 3.885 0 0114.5 2 3.418 3.418 0 0118 5.5c0 2.89-3.14 5.74-7.9 10.05z';
			favoriteSwitch.firstElementChild.firstElementChild.setAttribute('d', path);
		} else {
			favoriteSwitch.classList.add('is-select');
			let path = 'M10 18.35l-1.45-1.32C3.4 12.36 0 9.28 0 5.5A5.447 5.447 0 015.5 0 5.988 5.988 0 0110 2.09 5.988 5.988 0 0114.5 0 5.447 5.447 0 0120 5.5c0 3.78-3.4 6.86-8.55 11.54z';
			favoriteSwitch.firstElementChild.firstElementChild.setAttribute('d', path);
		}
	});
});

// PRODUCT-LIST COLUMN CHANGE
const columnChanger = document.querySelectorAll('.js-columnChanger .p-search-columnSwitch__item');
Array.prototype.forEach.call(columnChanger, (columnChanger) => {
	columnChanger.addEventListener('click', (e) => {
		if (window.innerWidth < 1024) {

			let target = document.getElementsByClassName('p-productList__list')[0];
			const columnChangerAddClass = () => {
				if (!target.classList.contains('is-columnChanger--1')) {
					target.classList.add('is-columnChanger--1');
				} else {
					target.classList.remove('is-columnChanger--1');
				}
			};

			const columnChangerJudg = () => {
				if(columnChanger.dataset.colmun == 1){
					columnChangerAddClass();
				}else{
					if(target.classList.contains('is-columnChanger--1')){
						target.classList.remove('is-columnChanger--1');
					}
				}
			}

			if (!columnChanger.classList.contains('is-current')) {
				let elLength = document.querySelectorAll('.js-columnChanger .p-search-columnSwitch__item').length;
				for (let i = 0; i < elLength; i++) {
					document.querySelectorAll('.js-columnChanger .p-search-columnSwitch__item')[i].classList.remove('is-current');
				};
				columnChanger.classList.add('is-current');
				columnChangerJudg();
			}

		}
	});
});

// TEXT ABRIDGEMENT 34characters
const textAbridgement34 = document.querySelectorAll('.js-text-abridgement');
const textAbridgemen = (el, num) => {
	for (let i = 0; i < el.length; i++) {
		let text = el[i].textContent;
		let textNum = text.length;
		if (textNum > num) {
			let newText = text.replace(/\s+/g, "").substring(0, num);
			el[i].textContent = newText;
			el[i].classList.add('is-abridgement')
		}
	}
};
if (textAbridgement34.length) {
	textAbridgemen(textAbridgement34, 34);
};

const accodion = (el, conts) => {
	if (el.classList.contains('is-open')) {
		el.classList.remove('is-open');
		if (conts) {
			el.conts.setAttribute('style', 'height: 0;');
		} else {
			el.nextElementSibling.setAttribute('style', 'height: 0;');
		}
	} else {
		el.classList.add('is-open');
		let height = el.nextElementSibling.firstElementChild.clientHeight;
		el.nextElementSibling.setAttribute('style', 'height:' + height + 'px;')
	}
};

// modal
const modalSwitch = document.querySelectorAll('.js-modal');
const modal = () => {
	const modalWrap = document.getElementsByClassName('p-modal');
	const modalContents = document.querySelectorAll('.p-modal__item');
	const modalButton = document.getElementsByClassName('js-modal__close');
	const modalOverlay = document.getElementsByClassName('p-modal__overlay');
	const html = document.getElementsByTagName('html')[0];
	const body = document.getElementsByTagName('body')[0];

	const modalOpen = (el) => {
		let ySctoll = window.pageYOffset;
		sessionStorage.setItem('ySctoll', ySctoll);
		body.setAttribute('style', 'position:fixed; top:' + -ySctoll + 'px;');
		html.setAttribute('style', 'overflow:hidden');
		for (let i = 0; i < modalContents.length; i++) {
			let modalName = modalContents[i].dataset.modal;
			modalContents[i].classList.remove('is-show');
			if (el == modalName) {
				modalContents[i].classList.add('is-show');
			} else {
			}
		}
	}

	// モーダル　クローズ
	const modalClose = () => {
		modalWrap[0].classList.remove('is-show');
		for (let i = 0; i < modalContents.length; i++) {
			modalContents[i].classList.remove('is-show');
		}
		body.setAttribute('style', '');
		html.setAttribute('style', '');
		let data = sessionStorage.getItem('ySctoll');
		scrollTo(0, data);
	}

	// モーダル　オープン
	Array.prototype.forEach.call(modalSwitch, function (modalSwitch) {
		modalSwitch.addEventListener('click', (e) => {
			e.preventDefault();
			e.stopPropagation();

			modalWrap[0].classList.add('is-show');
			let modalNum = modalSwitch.dataset.modal;
			modalOpen(modalNum);
		})
	});

	// モーダル以外の場所をクリックでモーダル閉じる
	document.addEventListener('click', (e) => {
		if (modalWrap[0].classList.contains('is-show')) {
			if (!e.target.closest('.p-modal__item') && !e.target.classList.contains('js-modal')) {
				modalClose();
			}
		}
	})

	// モーダル 閉じるボタン
	Array.prototype.forEach.call(modalButton, function (modalButton) {
		modalButton.addEventListener('click', (e) => {
			modalClose();
		});
	});

};
if (modalSwitch.length) {
	modal();
}

// SNS SHARE
const shareButtonLink = (() => {
	// SNS Share
	const snsShare = document.getElementsByClassName('js-snsShare');

	// SNS　有無
	if (snsShare.length) {
		const pageUrl = location.href;
		const pageTitle = encodeURI(document.title);
		const productImage = document.getElementsByClassName('p-productImages--carousel')[0].getElementsByClassName('swiper-slide')[0].getElementsByTagName('img')[0].getAttribute('data-src');

		// 各要素への処理
		window.addEventListener('load', () => {
			for (let i = 0; i < snsShare[0].children.length; i++) {
				let sns = snsShare[0].children[i];
				snsGenerate(sns);
			}
		});

		// 各SNSのURL生成
		const snsGenerate = (el) => {

			// FACEBOOK
			if (el.classList.contains('type-facebook') == true) {
				const fBLink = 'https://www.facebook.com/sharer/sharer.php?u=' + pageUrl + '';
				hrefSetting(el, fBLink);
				setTimeout(function () {
					(function (d, s, id) {
						var js, fjs = d.getElementsByTagName(s)[0];
						if (d.getElementById(id)) return;
						js = d.createElement(s); js.id = id;
						js.src = '//connect.facebook.net/ja_JP/sdk.js#xfbml=1&version=v8.0';
						fjs.parentNode.insertBefore(js, fjs);
					}(document, 'script', 'facebook-jssdk'));
				}, 0);
				return false;
			}

			// TWITTER
			if (el.classList.contains('type-twitter') == true) {
				const twLink = 'https://twitter.com/intent/tweet?url=' + pageUrl + '&text=' + pageTitle + '&tw_p=tweetbutton';
				hrefSetting(el, twLink);
				return false;
			}

			// LINE
			if (el.classList.contains('type-line') == true) {
				const lineLink = 'http://line.naver.jp/R/msg/text/?' + pageUrl;
				hrefSetting(el, lineLink);
				return false;
			}

			// PINTEREST
			if (el.classList.contains('type-pinterest') == true) {
				const pinterestLink = 'http://pinterest.com/pin/create/button/?url=' + pageUrl + '&media=' + productImage + '&description=' + pageTitle;
				hrefSetting(el, pinterestLink);
				return false;
			}
		};

		// URL set
		const hrefSetting = (el, url) => {
			el.firstElementChild.setAttribute('href', url);
		}

	}
})();


// COMMON SINGLE SELECT
const singleSlect = (el) => {
	for (let i = 0; i < el.children.length; i++) {
		el.children[i].addEventListener('click', (e) => {
			if (!el.children[i].classList.contains('is-select')) {
				for (let i2 = 0; i2 < el.children.length; i2++) {
					el.children[i2].classList.remove('is-select');
				}
				el.children[i].classList.add('is-select');
			}
		});
	}
};
// SINGLE SELECT
const singleSelection = document.querySelectorAll('.js-single-select');
Array.prototype.forEach.call(singleSelection, (singleSelection) => {
	singleSlect(singleSelection);
});

// COMMON MULTIPLE SELECT
const multipleSlect = (el) => {
	for (let i = 0; i < el.children.length; i++) {
		el.children[i].addEventListener('click', (e) => {
			if (!el.children[i].classList.contains('is-select')) {
				el.children[i].classList.add('is-select');
			} else {
				el.children[i].classList.remove('is-select');
			}
		});
	}
};
const multipleSelection = document.querySelectorAll('.js-multiple-select');
Array.prototype.forEach.call(multipleSelection, (multipleSelection) => {
	multipleSlect(multipleSelection);
});


const deleteButton = document.querySelectorAll('.js-delete');
Array.prototype.forEach.call(deleteButton, (deleteButton) => {
	deleteButton.addEventListener('click', (e) => {
		deleteButton.parentNode.parentNode.removeChild(deleteButton.parentNode);
	})
});

document.addEventListener("DOMContentLoaded", () => {
  if (location.hash) {
    const headerHeight = header.clientHeight;
    setTimeout(() => {
      window.scrollBy(0, - headerHeight);
    },100)
  }
})





// Window resize
const mediaQuery = window.matchMedia('(min-width: 1025px)');
const pagereload = () => {
	location.reload();
}
const handleMediaQuery = function (mediaQuery) {
	if (mediaQuery.matches) {
		pagereload();
		// PC
		if (document.querySelectorAll('.p-productList__list').length) {
			if (document.getElementsByClassName('p-productList__list')[0].classList.contains('is-columnChanger--1')) {
				document.getElementsByClassName('p-productList__list')[0].classList.remove('is-columnChanger--1');
			}
		}
	} else {
		// SP
		pagereload();
		if (refinesearch) {
			if (document.querySelector(".p-sidebar__heading.is-open")) {
				let target = document.querySelectorAll(".p-sidebar__heading.is-open");
				for (let i = 0; i < target.length; i++) {
					target[i].classList.remove('is-open');
					target[i].nextElementSibling.removeAttribute('style');
				}
			}
		}
	}
};
mediaQuery.addListener(handleMediaQuery);



