document.addEventListener('click', (e) => {
	const target = e.target;
	const header = document.getElementsByClassName('l-header')[0];
	if(!e.target.getAttribute('href') == null){
		if(target.classList.contains('js-smoothscroll')){
			e.preventDefault();
			const targetId = target.hash;
			document.querySelector(targetId).scrollIntoView({
				behavior: 'smooth',
				block: 'start'
			});
		}else if(e.target.getAttribute('href').indexOf('#') == 0){
			e.preventDefault();
			const headerHeight = header.clientHeight;
			let hash = e.target.getAttribute('href')
			let topPos = document.querySelector(hash).offsetTop - headerHeight;
			window.scroll({ top: topPos, behavior: "smooth" })
			history.pushState(null, null, hash)
		}else{
			return;
		}
	}


});

import smoothscroll from 'smoothscroll-polyfill';

smoothscroll.polyfill();
