import noUiSlider from 'nouislider';
window.onload = () => {

	const sidePriceSlider = () => {
		var sliders = document.getElementsByClassName('js-priceselect');
		var slider_min;
		var slider_max;
		if (document.getElementById("price_v1_sp").value === "" || document.getElementById("price_v1_sp").value === null) {
			slider_min = 0
		} else {
			slider_min = document.getElementById("price_v1_sp").value
		}
		if (document.getElementById("price_v2_sp").value === "" || document.getElementById("price_v2_sp").value === null) {
			slider_max = 100000
		} else {
			slider_max = document.getElementById("price_v2_sp").value
		}
		for (var i = 0; i < sliders.length; i++) {
			var slider = sliders[i];
			var options = {
				range: {
					min: [0, 1],
					max: [100000]
				},
				start: [slider_min, slider_max],
			}
			noUiSlider.create(slider, options);
			slider.noUiSlider.on('update', function (values, handle) {

				var parentNode = this.target.parentNode; // this.target で update した slider を取得できる

				parentNode.getElementsByClassName('c-priceselect--min')[0].value = parseInt(values[0]);
				parentNode.getElementsByClassName('c-priceselect--max')[0].value = parseInt(values[1]);
			});
		}
	}


	if (document.getElementsByClassName('js-priceselect').length) {
		sidePriceSlider();

		const priceReset = document.querySelectorAll('.js-price-reset');
		Array.prototype.forEach.call(priceReset, (priceReset) => {
			priceReset.addEventListener('click', (e) => {
				const priceSlider = document.getElementsByClassName('c-price--bar')[0];
				priceSlider.noUiSlider.set([0, 100000]);
			});
		});

	}


};



