const pagetop = document.getElementsByClassName('c-pagetop');
const refinebutton = document.getElementsByClassName('p-refinesearch__button--fix');
let windowHeight = window.innerHeight;
let positionY = window.scrollY || window.pageYOffset;

if (windowHeight < positionY) {
	if(refinebutton.length){
		refinebutton[0].classList.remove('is-hide');
	}
	if(pagetop.length){
		pagetop[0].classList.remove('is-show');
	}
}

document.addEventListener('scroll', function () {
	let positionY = window.scrollY || window.pageYOffset;
	if (positionY > windowHeight) {
		if(pagetop.length){
			pagetop[0].classList.add('is-show');
		}
		if(refinebutton.length){
			refinebutton[0].classList.remove('is-hide');
		}
	} else {
		if(pagetop.length){
			pagetop[0].classList.remove('is-show');
		}
		if(refinebutton.length){
			refinebutton[0].classList.add('is-hide');
		}
	}
});

